<script>
// @ is an alias to /src
import Reco from "@/components/home/Reco.vue";

export default {
  	name: "Home",
  	components: {
		Reco,
	},
	mounted() {
		document.title = this.$route.meta.title
	}
};
</script>

<template>
  	<div class="home">
		<Reco></Reco>
	</div>
</template>

<style scoped>
	.home {
		@apply flex justify-center w-full p-10;
	}
</style>
