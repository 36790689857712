import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import NProgress from 'nprogress' // 引入nprogress插件
import 'nprogress/nprogress.css'  // 这个nprogress样式必须引入
NProgress.configure({showSpinner: false, ease:'ease', speed:200, minimum:0.3}); // 进度环隐藏，动画效果，速度，最低百分比

import './index.css'
import 'font-awesome/css/font-awesome.min.css'
import 'font-awesome/css/font-awesome.css'

const app = createApp(App)
app.use(router)  // 使用路由


// 全局设置ElementPlus
import ElementPlus from 'element-plus'
import { ElNotification } from 'element-plus'
import 'element-plus/dist/index.css'

// element-plus icon
import * as Icons from '@element-plus/icons'

// 注册Icons 全局组件
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key])
})

app.use(ElementPlus)

// 全局引入Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
app.use(VueAxios, axios);
// app.axios.defaults.baseURL = 'http://127.0.0.1:8080';

// v-fit-columns
import Plugin from 'v-fit-columns';
app.use(Plugin)

// vuex
import Vuex from 'vuex'
const store = new Vuex.Store({
    state: {
        authenticated_status: false,
        authenticated_username: '',
        authenticated_email: '',
        authenticated_avatar: '',
    },
    mutations: {
        updateAuthenticatedUser(state, [status, username, email, avatar]) {
            state.authenticated_status = status;
            state.authenticated_username = username;
            state.authenticated_email = email;
            state.authenticated_avatar = avatar;
        }
    },
})

app.use(store)

// vue-cookies
import VueCookies from 'vue-cookies'
app.config.globalProperties.$cookies = VueCookies;

// 全局路由守卫
router.beforeEach(function(to, from, next) {
    NProgress.start()
    let isKey = VueCookies.isKey("token");
    if (to.name === "Login") {
        if (isKey) {
            next({
                path: '/'
            });
        } else {
            next();
        }
    } else if (to.name === "Cloud" || to.name === "Live" || to.name === "Idea") {
        if (isKey) {
            next();
        } else {
            ElNotification.error({
                title: '无权限',
                message: '请先登录!',
                position: 'bottom-right',
            });
            next({
                path:'/login'
            })
        }
    } else {
        next();
    }
    NProgress.done()
});


// 挂载 #app
app.mount('#app')


// markdown start
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index'; // 代码行号
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index'; // 代码高亮
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'; // 快速复制代码
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';  // To-Do List
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn'; // KaTex 数学公式插件
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index'; // 提示插件
import '@kangc/v-md-editor/lib/plugins/tip/tip.css';

// emoji
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';

// 引入语言包
import hljs from 'highlight.js';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'; // 快速复制代码

VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs,
}, );
VueMarkdownEditor.use(createKatexPlugin()); // 
VueMarkdownEditor.use(createLineNumbertPlugin());
VueMarkdownEditor.use(createHighlightLinesPlugin());
VueMarkdownEditor.use(createCopyCodePlugin());
VueMarkdownEditor.use(createEmojiPlugin());
VueMarkdownEditor.use(createTodoListPlugin());
VueMarkdownEditor.use(createTipPlugin());

app.use(VueMarkdownEditor);

// markdown end