<script>
    export default {
        mounted() {
            // 点击其他地方authenticatedOptionsWrapperShow = false 用户信息窗口关闭
            document.addEventListener('click', (e) => {
                if (!this.$el.contains(e.target)) this.authenticatedOptionsWrapperShow = false
            })
        },
        data () {
            return {
                menu_items: [
                    {
                        name: '首页',
                        route: '/',
                        existSecMenu: false
                    },
                    {
                        name: '文章', 
                        route: '/article',
                        existSecMenu: false,
                        secMenu: [
                            {
                                name: 'LINUX',
                                route: '/article/linux'
                            }
                        ]
                    },
                    {
                        name: '云',
                        route: '/cloud',
                        existSecMenu: false
                    },
                    {
                        name: '小工具',
                        route: '/live',
                        isHover: false,
                        existSecMenu: true,
                        secMenu: [
                            {
                                name: '高能时刻',
                                route: '/live/highlight'
                            },
                            {
                                name: '仓库管理',
                                route: '/live/warehouse'
                            }
                        ]
                    },
                    {
                        name: '创作中心',
                        route: '/idea',
                        existSecMenu: false
                    },
                ],
                login_items: [
                    {
                        name: '登录',
                        route: '/login',
                        existSecMenu: false
                    },
                ],
                authenticatedOptionsWrapperShow: false,
            }
        },
        methods: {
            logout() {
                this.axios({
                    url: "/request/login/logout",
                    method: 'GET',
                }).then(resp => {
                    if (resp.data.result === "success") {
                        this.$cookies.remove("token");
                        location.reload();
                    }
                });
            },
            showAuthenticatedOptionsWrapperSwitch() {
                this.authenticatedOptionsWrapperShow = !this.authenticatedOptionsWrapperShow;
            }
        }
    }
</script>

<template>
    <div class="navbar-pc">
        <div class="navbar__wrapper">
            <div class="navbar__menu__wrapper">
              <div class="navbar__brand__wrapper">M4LLKN0W.CN</div>
                <div class="navbar__menu__item" v-for="item in menu_items">
                    <div class="navbar__menu__item__text">
                        <router-link v-bind:to="item.route">{{item.name}}</router-link>
                        <div class="navbar__sec-menu__item" v-if="item.existSecMenu">
                            <div class="navbar__sec-menu__item__text" v-for="secItem in item.secMenu">
                                <router-link v-bind:to="secItem.route">{{secItem.name}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar__login__wrapper" v-if="!this.$store.state.authenticated_status">
                <div class="navbar__login__item" v-for="item in login_items">
                    <div class="navbar__login__item__text">
                        <router-link v-bind:to="item.route">{{item.name}}</router-link>
                    </div>
                </div>
            </div>
            <div class="navbar__authenticated__wrapper" v-show="this.$store.state.authenticated_status" >
                <div class="navbar__notification navbar__authenticated__user">
                    <el-badge is-dot>
                        <i class="fa fa-bell-o" aria-hidden="true"></i>
                    </el-badge>
                </div>
                <div class="navbar__authenticated__user" @click="showAuthenticatedOptionsWrapperSwitch">
                    <div class="navbar__authenticated__user__avatar__wrapper">
                        <div class="navbar__authenticated__user__avatar" :style="{backgroundImage: 'url('+ this.$store.state.authenticated_avatar +')'}"></div>
                    </div>
                    <span>{{this.$store.state.authenticated_username}}</span>
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                </div>
                <div class="navbar__authenticated__user__options__wrapper" :class="{show__in: this.authenticatedOptionsWrapperShow, hidden__out: !this.authenticatedOptionsWrapperShow}">
                    <div class="navbar__authenticated__user__options__header">
                        <div class="navbar__authenticated__user__options__header__left">
                            <div class="navbar__authenticated__user__options__header__logo"><img src="https://static.mallknow.cn/images/favicon_mallknow.png" alt></div>
                            <div class="navbar__authenticated__user__options__header__text">M4LLKN0W.CN</div>
                        </div>
                        <button @click="logout">注销</button>
                    </div>
                    <div class="navbar__authenticated__user__options__body">
                        <div class="navbar__authenticated__user__options__body__left">
                            <div class="navbar__authenticated__user__options__body__left__avatar__wrapper">
                                <div class="navbar__authenticated__user__options__body__left__avatar__mask">
                                    <i class="fa fa-camera fa-2x" aria-hidden="true" style="color: #fff;"></i>
                                </div>
                                <div class="navbar__authenticated__user__options__body__left__avatar" :style="{backgroundImage: 'url('+ this.$store.state.authenticated_avatar +')'}"></div>
                            </div>
                        </div>
                        <div class="navbar__authenticated__user__options__body__right">
                            <div class="navbar__authenticated__user__options__body__acccount__info">
                                <div class="navbar__authenticated__user__options__body__acccount__username">
                                    <span>{{this.$store.state.authenticated_username}}</span>
                                </div>
                                <div class="navbar__authenticated__user__options__body__acccount__email">
                                    <span>{{this.$store.state.authenticated_email}}</span>
                                </div>
                                <div class="navbar__authenticated__user__options__body__acccount__settings">
                                    <a>我的个人信息</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .navbar-pc {
        @apply flex shadow-md flex-row justify-center h-14;
        /* background-color: #f9f9f9;*/
        background-color: #fff;

    }

    .navbar__wrapper {
        @apply flex w-full max-w-screen-2xl justify-between;
    }

    .navbar__menu__wrapper {
        @apply flex;
    }
    .navbar__brand__wrapper {
        @apply flex items-center text-blue-600 border-gray-300 px-4 border-l border-r text-2xl font-bold select-none;
        font-family: "Montserrat";
        /* color: #0075ff; */
    }

    .navbar__menu__item, .navbar__login__item {
        @apply flex w-24 justify-center items-center hover:bg-gray-200 transition;
    }

    .navbar__menu__item:hover .navbar__sec-menu__item {
        @apply visible opacity-100;
    }

    .navbar__menu__item__text, .navbar__login__item__text {
        @apply flex w-full h-full justify-center;
    }

    .navbar__menu__item__text > a, .navbar__login__item__text > a {
        @apply flex relative justify-center items-center h-full w-full text-base hover:text-blue-600;
    }

    .navbar__sec-menu__item {
        @apply flex flex-col absolute w-28 top-14 bg-white opacity-0 invisible transition z-10;
    }

    .navbar__sec-menu__item__text {
        @apply flex h-10 border border-gray-300 hover:bg-gray-200 hover:text-blue-500 transition;
    }

    .navbar__sec-menu__item__text > a {
        @apply flex justify-center items-center w-full h-full text-sm;
        font-family: "Montserrat";
    }

    .navbar__login__wrapper {
        @apply flex justify-center ;
    }

    .navbar__authenticated__wrapper {
        @apply flex justify-center relative cursor-pointer;
    }

    .navbar__authenticated__user {
        @apply flex flex-row w-auto px-4 justify-center items-center space-x-2 hover:bg-gray-200 transition select-none;
    }

    .navbar__authenticated__user__avatar {
        @apply w-8 h-8 bg-no-repeat bg-cover shadow-lg rounded-full;
    }

    .navbar__authenticated__user span {
        @apply font-bold;
        font-family: "Montserrat";
    }

    .navbar__authenticated__user__options__wrapper {
        @apply absolute top-14 right-0 w-96 bg-white shadow-lg cursor-default rounded z-50;
    }

    .navbar__authenticated__user__options__header {
        @apply flex flex-row justify-between items-center h-10;
    }

    .navbar__authenticated__user__options__header__left {
        @apply flex flex-row justify-center items-center h-full space-x-1 select-none;
        width: 180px;
    }

    .navbar__authenticated__user__options__header__logo {
        @apply flex justify-center items-center w-5 h-5;
    }

    .navbar__authenticated__user__options__header__text {
        @apply font-bold h-6;
        font-family: "Montserrat";
    }

    .navbar__authenticated__user__options__header button {
        @apply w-16 h-full text-sm text-center hover:bg-gray-200 transition;
    }

    .navbar__authenticated__user__options__body {
        @apply flex flex-row justify-evenly p-5;
    }

    .navbar__authenticated__user__options__body__left {
        @apply border-2 border-gray-500 rounded-full shadow-md overflow-hidden;
    }

    .navbar__authenticated__user__options__body__left__avatar__wrapper {
        @apply items-center relative w-24 h-24;
    }

    .navbar__authenticated__user__options__body__left__avatar__mask {
        @apply flex justify-center items-center absolute w-full h-full z-10 opacity-0 rounded-full cursor-pointer transition;
    }

    .navbar__authenticated__user__options__body__left__avatar__mask:hover {
        background-color: rgb(0, 0, 0, 0.5);
        @apply opacity-100;
    }

    .navbar__authenticated__user__options__body__left__avatar {
        @apply absolute w-full h-full bg-center bg-cover;
    }

    .navbar__authenticated__user__options__body__right {
        @apply flex flex-col justify-evenly;
    }

    .navbar__authenticated__user__options__body__acccount__info {
        @apply flex flex-col h-full justify-evenly;
    }

    .navbar__authenticated__user__options__body__acccount__username {
        @apply text-lg font-bold;
        font-family: "Montserrat";
    }

    .navbar__authenticated__user__options__body__acccount__email {
        @apply text-sm;
    }

    .navbar__authenticated__user__options__body__acccount__settings {
        @apply text-sm text-blue-500 transition;
    }

    .navbar__authenticated__user__options__body__acccount__settings a {
        @apply cursor-pointer;
    }

    .navbar__authenticated__user__options__body__acccount__settings a:hover {
        @apply border-b border-blue-500;
    }
    
    .show__in {
        animation: fade-in 200ms forwards;
    }

    .hidden__out {
        @apply hidden;
    }

    @keyframes fade-in {
        0% {
            @apply visible opacity-0;
        }

        100% {
            @apply visible opacity-100;
        }
    }

    @keyframes fade-out {
        0% {
            @apply visible opacity-100;
        }

        100% {
            @apply invisible opacity-0;
        }
    }
</style>
