<script>
import NavbarPc from "@/components/navbar/NavbarPc.vue";
import Footer from "@/components/footer/Footer.vue";

export default {
	components: {
		NavbarPc,
		Footer
	},
	created() {
		this.axios({
			url: "/request/getUserinfo/",
			method: 'GET'
		}).then(resp => {
			if (resp.data.result === "success") {
				this.$store.commit('updateAuthenticatedUser', [true, resp.data.username, resp.data.email, resp.data.avatar]);
				this.$cookies.set("token", resp.data.token, "1m");
			} else if (resp.data.result === "fail") {
				if (this.$cookies.isKey("token")) {
					this.$cookies.remove("token");
					this.$notify({
						title: "Login Fail",
						message: resp.data.message,
						type: "error",
						position: "bottom-right"
					})
					return false;
				}
			}
		});
	},
	data() {
		return {

		}
	},
	methods: {

	}
}
</script>

<template>
	<div id="nav">
		<NavbarPc></NavbarPc>
	</div>
	<router-view />
	<Footer></Footer>
	<el-backtop />
</template>

<style scoped></style>
