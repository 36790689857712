<script>
// @ is an alias to /src

export default {
    name: "Footer",
    components: {},
};
</script>

<template>
    <div class="footer">
        <div class="footer__content__wrapper">
            <div class="footer__content__logo__wrapper">
                <div class="footer__logo"></div>
            </div>
            <div class="footer__divide__line"></div>
            <div class="footer__content__text__wrapper">
                <p><a href="http://beian.miit.gov.cn">桂ICP备2021008112号</a></p>
                <p>Copyright © 2021-2023 Mallknow Blog</p>
                <p>Developed by <strong>Mallknow</strong> All rights reserved</p>
                <p>Special thanks to <strong>Monody</strong> for Technical Support</p>
                <p>Special thanks to <strong><a href="https://space.bilibili.com/451368848"
                            target="_blank">CodingStartup</a></strong></p>
                <p>Special thanks to <strong><a href="https://www.acwing.com/" target="_blank">AcWing.com</a></strong></p>
                <p>Version 0.1.1-20231027</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.footer {
    @apply flex justify-center items-center w-full h-56;
    background-color: #e5e5e5;
}

.footer__logo {
    @apply w-20 h-20 bg-no-repeat;
    background-image: url("https://static.mallknow.cn/images/favicon_mallknow.png");
}

.footer__content__wrapper {
    @apply flex flex-row justify-around h-4/5;
    width: 500px;
}

.footer__content__logo__wrapper {
    @apply flex justify-center items-center;
}

.footer__divide__line {
    @apply h-full;
    width: 1px;
    border-right: 1px solid #9b9b9b;
}

.footer__content__text__wrapper {
    @apply flex flex-col justify-evenly items-center;
}

.footer__content__text__wrapper>p {
    color: #6b6b6b;
    font-size: 0.8rem;
}

.footer__content__text__wrapper a {
    @apply text-blue-500;
}
.footer__content__text__wrapper a :hover{
    @apply text-gray-700 transition;
}

</style>
