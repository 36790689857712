import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: '首页 - M4LLKN0W'
		},
	},
	{
		path: '/article',
		name: 'Share',
		component: () => import('../views/Share.vue'),
		meta: {
			title: '文章 - M4LLKN0W'
		},
	},
	{
		path: '/article/detail/:all',
		name: 'ArticleDetailPage',
		component: () => import('../views/ArticlePage.vue'),
		meta: {
			title: '文章详情 - M4LLKN0W'
		},
	},
	{
		path: '/cloud',
		name: 'Cloud',
		component: () => import('../views/Cloud.vue'),
		meta: {
			title: '云 - M4LLKN0W'
		},
	},
	{
		path: '/live',
		name: 'Live',
		component: () => import('../views/Live.vue'),
		meta: {
			title: '高能时刻 - M4LLKN0W'
		},
	},
	{
		path: '/live/highlight',
		name: 'Highlight',
		component: () => import('../views/Live.vue'),
		meta: {
			title: '高能时刻 - M4LLKN0W'
		},
	},
	{
		path: '/live/warehouse',
		name: 'Warehouse',
		component: () => import('../views/Live.vue'),
		meta: {
			title: '仓库管理 - M4LLKN0W'
		},
	},
	{
		path: '/idea',
		name: 'Idea',
		component: () => import('../views/Idea.vue'),
		meta: {
			title: '创作中心 - M4LLKN0W'
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
		meta: {
			title: '登录 - M4LLKN0W'
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/Register.vue'),
		meta: {
			title: '注册 - M4LLKN0W'
		},
	},
	{
		path: '/:all*',
		name: 'Error',
		component: () => import('../views/Error.vue'),
		meta: {
			title: 'Error - M4LLKN0W'
		},
	}
]

const router = createRouter({
	// history: createWebHashHistory(),
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
