<script>
export default {
    name: "Reco",
    data() {
        return {
            recoArticles: [],
        }
    },
    mounted() {
        this.getRecoArticles();
    },
    methods: {
        getRecoArticles() {
            this.axios({
                'url': 'https://www.mallknow.cn/request/article/getarticles/',
                'methods': 'GET'
            }).then(resp => {
                this.recoArticles = resp.data.articles
            })
        },
        jumpToArticleDetail(articleID) {
            let url = "https://www.mallknow.cn/article/detail/" + articleID
            window.location.href = url
        }
    }
}
</script>

<template>
    <div class="reco">
        <div class="reco_wrapper">
            <div class="reco_item-loading" v-if="!this.recoArticles.length">
                <el-skeleton animated>
                    <template #template>
                        <div class="article-loading-container space-y-4">
                            <div class="article-author flex items-center space-x-4" style="--el-skeleton-circle-size: 40px">
                                <el-skeleton-item variant="circle" />
                                <el-skeleton-item variant="text" style="width: 200px" />
                            </div>
                            <div class="article-main">
                                <el-skeleton :rows="2" />
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </div>
            <div class="reco_item" v-if="this.recoArticles.length" v-for="article in this.recoArticles">
                <div class="flex flex-col w-full space-y-4" @click="jumpToArticleDetail(article.article_id)">
                    <div class="article-author flex items-center space-x-4">
                        <div class="article-author-avatar" :style="{backgroundImage: 'url('+ article.avatar +')'} "></div>
                        <div class="article-author-name">{{article.author}}</div>
                    </div>
                    <div class="article-main space-y-5">
                        <div class="article-title font-extrabold text-xl">{{article.title}}</div>
                        <div class="article-description">{{article.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .reco {
        @apply flex justify-center w-full;
    }

    .reco_wrapper {
        @apply w-4/5 space-y-10;
    }

    .reco_item-loading {
        @apply flex justify-center items-center w-full h-48 px-5 bg-white shadow-md hover:shadow-lg rounded-lg transition cursor-pointer;
    }

    .reco_item-loading:hover {
        transform: scale(1.02);
    }

    .reco_item {
        @apply flex justify-center items-center w-full h-48 px-5 bg-white shadow-md hover:shadow-lg rounded-lg transition cursor-pointer;
    }

    .reco_item:hover {
        transform: scale(1.02);
    }

    .article-author-avatar {
        @apply w-8 h-8 bg-no-repeat bg-cover rounded-full shadow-2xl;
    }

    .article-author-name {
        @apply font-bold;
        font-family: "Montserrat";
    }
</style>